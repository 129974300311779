<template>
  <line-chart
    title="Chiffre d'affaire"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
    outlined
  ></line-chart>
</template>

<script>
import debounce from "lodash/debounce";

import LineChart from "@/components/common/charts/LineChart";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

import useAxios from "@/hooks/useAxios";

import { colors } from "@/utils/constants";
import { getDate, getDateStr, getDaysArray } from "@/utils/dates";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "CaChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      colors: [colors.ca],
      series: [
        {
          name: "CA",
          data: [],
        },
      ],
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (value) => formatCurrency(value, 0),
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
      xAxis: {
        categories: [],
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    safeDateRange() {
      if (this.periodType === defaultPeriodType) {
        return [this.startDate, this.endDate];
      }

      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        return ["", ""];
      }

      const [yyyy, mm, dd] = this.startDate.split("-");
      const safeStartDate = new Date(yyyy, mm - 1, dd);
      const articlesCreatedStartDate = getDate(-30);
      const articlesCreatedSafeStartDate =
        safeStartDate > articlesCreatedStartDate
          ? safeStartDate
          : articlesCreatedStartDate;

      return [getDateStr(articlesCreatedSafeStartDate), getDateStr(getDate())];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const [startDate, endDate] = this.safeDateRange();
        const categories = getDaysArray(startDate, endDate);
        this.xAxis = {
          ...this.xAxis,
          categories: categories,
        };

        this.loading = true;
        const { data } = await this.axiosGet("/audience/ca", {
          start_date: this.startDate,
          end_date: this.endDate,
          sites: this.sites.join(","),
          period_type: this.periodType,
          categories: this.categories.join(","),
        });

        this.series = [
          {
            name: "CA",
            data: categories.map((date) => data[date]?.ca ?? 0),
          },
        ];

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    categories() {
      this.debouncedGetData();
    },
    periodType: function () {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

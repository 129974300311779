<template>
  <v-select
    outlined
    clearable
    v-model="selected"
    :items="search_type_items"
    :menu-props="{ maxHeight: '200', zIndex: 13 }"
    label="Type de recherche"
  ></v-select>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

/**
 * Call this component specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
const DEFAULT_VALUE = "contains";

export default {
  name: "SearchType",
  props: {
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["audience", "regie"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getSearchType",
    },
    storeUpdater: {
      type: String,
      default: "updateSearchType",
    },
    anchor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      initialized: false,
      search_type_items: [
        { text: "Valeur exacte", value: "exact-match" },
        { text: "Commençant par", value: "starts-with" },
        { text: "Mot commençant par", value: "word-starts-with" },
        { text: "Contenant", value: DEFAULT_VALUE },
      ],
    };
  },
  async created() {
    this.selected = DEFAULT_VALUE;

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: `${this.store}/${this.storeUpdater}`,
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.selected,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });

    this.initialized = true;
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters[`${this.store}/${this.storeGetter}`];
      },
      set(e) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
  watch: {
    selected(newValue) {
      if (this.initialized) {
        // Only update user changes, not initialization
        addQueryStringParam(
          this.$router,
          this.$route,
          URL_PARAM_NAMES[this.$options.name],
          newValue,
          this.anchor
        );
      }
    },
  },
};
</script>

<style scoped></style>
